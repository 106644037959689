import axios from 'axios'
const URL_API = '/api'
import LogServices from '@/assets/js/services/LogServices'

const getAll = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Representante`)
        return data;
    } catch (e) {
        LogServices.logErro(e, 'getAllRepresentante')
        throw (e)
    }
}

const getById = async (idRepresentante) => {
    try {
        const { data } = await axios.get(`${URL_API}/Representante/${idRepresentante}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'getByIdRepresentante')
        throw (e)
    }
}

const getPagina = async ({ nomeFiltrado, ordenacao = 'DESC', paginaAtual, tamanhoPagina }) => {
    try {
        const { data } = await axios.get(`${URL_API}/Representante/pagina`, {
            params: {
                'Nome': nomeFiltrado,
                'Ordenacao': ordenacao,
                'PaginaAtual': paginaAtual,
                'TamanhoPagina': tamanhoPagina,
            }
        })
        return data
    } catch (e) {
        console.log('erro getPaginaClientes', e)
        throw (e)
    }
}

const post = async (representante) => {
    try {
        const { data } = await axios.post(`${URL_API}/Representante`, representante)
        return data
    } catch (e) {
        LogServices.logErro(e, 'postRepresentante')
        throw (e)
    }
}

const update = async (representante) => {
    try {
        const { data } = await axios.put(`${URL_API}/Representante/${representante.Id}`, representante)
        return data
    } catch (e) {
        LogServices.logErro(e, 'updateRepresentante')
        throw (e)
    }
}

const deleteById = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/Representante/${id}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'deleteRepresentante')
        throw (e)
    }
}

export default {
    getAll,
    getById,
    getPagina,
    post,
    update,
    deleteById,
}